import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { CartFragment, ConfirmMergedOrderLinesDocument } from '../../../common/gql/graphql';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { DataService } from '../../../core/providers/data/data.service';
import { Dialog } from '../../../core/providers/modal/modal-types';
import { FormatPricePipe } from '../../pipes/format-price.pipe';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { DialogButtonsDirective } from '../modal-dialog/dialog-buttons.directive';
import { NgFor, NgIf } from '@angular/common';
import { DialogTitleDirective } from '../modal-dialog/dialog-title.directive';

@Component({
    selector: 'kb-order-merged-dialog',
    templateUrl: './order-merged-dialog.component.html',
    styleUrls: ['./order-merged-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [
        DialogTitleDirective,
        NgFor,
        NgIf,
        DialogButtonsDirective,
        CheckboxComponent,
        FormatPricePipe,
    ],
})
export class OrderMergedDialogComponent implements OnInit, Dialog<void> {
    order: CartFragment;
    addedOrderLines: CartFragment['lines'] = [];
    lineIdsToKeep: string[] = [];
    compact = false;

    constructor(private dataService: DataService) {}

    resolveWith: (result: void | undefined) => void;

    ngOnInit(): void {
        this.addedOrderLines = this.order.mergedOrderLines
            .map((merged) => {
                return this.order.lines.find((l) => l.id === merged.orderLineId);
            })
            .filter(notNullOrUndefined);
    }

    isSelected(id: string): boolean {
        return this.lineIdsToKeep.includes(id);
    }

    toggleKeepLine(id: string) {
        if (this.lineIdsToKeep.includes(id)) {
            this.lineIdsToKeep = this.lineIdsToKeep.filter((_id) => _id !== id);
        } else {
            this.lineIdsToKeep.push(id);
        }
    }

    toggleAll() {
        if (this.lineIdsToKeep.length === this.addedOrderLines.length) {
            this.lineIdsToKeep = [];
        } else {
            this.lineIdsToKeep = this.addedOrderLines.map((l) => l.id);
        }
    }

    close() {
        const input = this.addedOrderLines.map((line) => {
            const keep = this.lineIdsToKeep.includes(line.id);
            return {
                orderLineId: line.id,
                keep,
            };
        });
        this.dataService
            .mutate(ConfirmMergedOrderLinesDocument, {
                input,
            })
            .subscribe(() => this.resolveWith());
    }
}
