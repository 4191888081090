<div *ngIf='activeCustomer$ | async as customer; else notLoggedIn'>
    <a class='btn-icon' [routerLink]="['/account']">
        <fa-icon [icon]='userCircle'></fa-icon>
        <span class='hidden md:inline-block text-sm ml-2 text-gray-600'>My Account</span>
    </a>
    <div class='hidden md:block text-xs text-primary-700 text-right pr-1'>{{ customer.firstName | slice:0:1 }} {{ customer.lastName }}</div>
</div>
<ng-template #notLoggedIn>
    <a class='btn-icon text-sm whitespace-nowrap lg:text-base text-gray-700' [routerLink]="['account/sign-in']">Sign In</a>
</ng-template>
