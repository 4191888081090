import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { map, switchMap, take } from "rxjs/operators";

import { GetCollectionIdBySlugDocument, GetRedirectDocument } from "../common/gql/graphql";
import { DataService } from "../core/providers/data/data.service";
import { ServerResponseService } from "../core/providers/server-response/server-response.service";

import { of } from "rxjs";
import { getSlugFromLegacyPath, LEGACY_CAT_URL_RE } from "./redirect-shared";

@Injectable({ providedIn: 'root' })
export class LegacyCategoryGuard  {
    constructor(
        private dataService: DataService,
        private router: Router,
        private serverResponseService: ServerResponseService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const slug = getSlugFromLegacyPath(LEGACY_CAT_URL_RE, route.url[0]?.path);
        const path = '/' + (route.url[0]?.path ?? '');
        return this.dataService
            .query(
                GetRedirectDocument,
                {
                    path,
                },
                { ssr: true },
            )
            .pipe(
                map((data) => data.redirectForPath),
                take(1),
                switchMap((result) => {
                    if (!result) {
                        return this.dataService
                            .query(
                                GetCollectionIdBySlugDocument,
                                {
                                    slug,
                                },
                                { ssr: true },
                            )
                            .pipe(map(({ collection }) => collection));
                    } else {
                        return of(result);
                    }
                }),
                map((result) => {
                    if (!result) {
                        return this.router.parseUrl('/c/404/' + slug);
                    } else if (typeof result === 'string') {
                        const redirectPath = result;
                        this.serverResponseService.redirect(redirectPath);
                        return this.router.parseUrl(redirectPath);
                    } else {
                        const redirectPath = '/c/' + slug;
                        this.serverResponseService.redirect(redirectPath);
                        return this.router.parseUrl(redirectPath);
                    }
                }),
            );
    }
}
