import { Injectable } from "@angular/core";
import { ModalService } from "../../core/providers/modal/modal.service";
import {
    FreeShippingPopupComponent
} from "../components/free-shipping-popup/free-shipping-popup/free-shipping-popup.component";
import {
    MailingListPopupComponent
} from "../components/free-shipping-popup/mailing-list-popup/mailing-list-popup.component";
import { PopupTriggerService } from "./popup-trigger.service";

@Injectable({
    providedIn: 'root',
})
export class MarketingPopupService {
    constructor(private popupTriggerService: PopupTriggerService, private modalService: ModalService) {    }

    registerPopups() {
        this.popupTriggerService.registerPopupTrigger(
            {
                campaignId: 'free-shipping-info',
                enableLogging: true,
                timesSeenByUserLessThan: 3,
                minimumOrderTotalQuantity: 1,
                shippingCountryCodeEquals: 'GB',
                orderQualifiesForFreeShipping: false,
                minTimeOnSiteSeconds: 110,
                minTimeBetweenTriggersSeconds: 60 * 60,
                minPagesVisited: 2,
            },
            () => {
                this.modalService.fromComponent(FreeShippingPopupComponent, {
                    closable: true,
                    size: 'sm'
                }).subscribe()
            },
        );
        this.popupTriggerService.registerPopupTrigger(
            {
                campaignId: 'join-mailing-list',
                enableLogging: true,
                timesSeenByUserLessThan: 2,
                customerIsSubscribedToMailingList: false,
                minTimeOnSiteSeconds: 90,
                minTimeBetweenTriggersSeconds: 60 * 60 * 24,
                minPagesVisited: 1,
            },
            () => {
                this.modalService.fromComponent(MailingListPopupComponent, {
                    closable: true,
                    size: 'full'
                }).subscribe()
            },
        );
    }
}
