import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { faHeart } from "@fortawesome/free-regular-svg-icons";

@Component({
    selector: 'kb-wishlist-link',
    templateUrl: './wishlist-link.component.html',
    styleUrls: ['./wishlist-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [RouterLink, FontAwesomeModule],
})
export class WishlistLinkComponent {
    heart = faHeart
}
