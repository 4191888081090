import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { map, take } from "rxjs/operators";

import { GetArticleDetailDocument } from "../article/common/gql/graphql";
import { DataService } from "../core/providers/data/data.service";
import { ServerResponseService } from "../core/providers/server-response/server-response.service";

import { getSlugFromLegacyPath, LEGACY_INFO_URL_RE } from "./redirect-shared";

@Injectable({ providedIn: 'root' })
export class LegacyArticleGuard {
    constructor(
        private dataService: DataService,
        private router: Router,
        private serverResponseService: ServerResponseService,
    ) {}
ap
    canActivate(route: ActivatedRouteSnapshot) {
        const slug = getSlugFromLegacyPath(LEGACY_INFO_URL_RE, route.url[0]?.path);
        return this.dataService
            .query(
                GetArticleDetailDocument,
                {
                    slug,
                },
                { ssr: true },
            )
            .pipe(
                map((data) => data.article),
                take(1),
                map((result) => {
                    if (!result) {
                        return this.router.parseUrl('/a/404/' + slug);
                    } else {
                        const redirectPath = `/a/${slug}`;
                        this.serverResponseService.redirect(redirectPath);
                        return this.router.parseUrl(redirectPath);
                    }
                }),
            );
    }
}
