// This file contains any site-wide scripts that should be loaded in the header, like analytics etc.

import { HeaderScript } from './core/providers/header-scripts/header-scripts.service';

export const googleTagManager: HeaderScript = {
    async: true,
    // language=JavaScript
    sourceCode: `
        window.dataLayer = window.dataLayer || [];
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MWT4JNX')
        `,
};

export const hotJar: HeaderScript = {
    // language=JavaScript
    sourceCode: `
     (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3295416,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `,
};

export const mailchimp: HeaderScript = {
    // language=JavaScript
    sourceCode: `
    !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/6031998d693e142e7c98b7e22/91ef609b6f1439ec8192e3f66.js");
    `,
};

export const dotDigital: HeaderScript = {
    // language=JavaScript
    sourceCode: `
     (function(w,d,u,t,o,c){w['dmtrackingobjectname']=o;c=d.createElement(t);c.async=1;c.src=u;t=d.getElementsByTagName
                (t)[0];t.parentNode.insertBefore(c,t);w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments);};
                })(window, document, '//static.trackedweb.net/js/_dmptv4.js', 'script', 'dmPt');
                window.dmPt('create', 'DM-0733105664-01', 'www.artsupplies.co.uk');
                `,
}

export const headerScripts: HeaderScript[] = [googleTagManager, dotDigital];
