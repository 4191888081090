import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'kb-livechat',
    templateUrl: './livechat.component.html',
    styleUrls: ['./livechat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class LivechatComponent implements AfterViewInit {
    constructor(@Inject(PLATFORM_ID) private platforId: any) {}
    private timer: any;
    ngAfterViewInit() {
        if (isPlatformBrowser(this.platforId)) {
            const loadChatWidgetOnMouseover = () => {
                this.timer = window.setTimeout(this.loadChatWidget, 5000);
                document.body.removeEventListener('mouseover', loadChatWidgetOnMouseover);
                window.removeEventListener('scroll', loadChatWidgetOnMouseover);
            };
            if (environment.production) {
                document.body.addEventListener('mouseover', loadChatWidgetOnMouseover, { passive: true });
                window.addEventListener('scroll', loadChatWidgetOnMouseover, { passive: true });
            }
        }
    }

    private loadChatWidget() {
        const BASE_URL = 'https://app.chatwoot.com';
        const g = document.createElement('script');
        g.src = BASE_URL + '/packs/js/sdk.js';
        g.defer = true;
        g.async = true;
        document.body.appendChild(g);
        g.onload = () => {
            (window as any).chatwootSDK?.run({
                websiteToken: '8bby8BQhrcbu5h2fuE7mhoja',
                baseUrl: BASE_URL,
            });
        };
    }
}
