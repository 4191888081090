import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { UntypedFormControl, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from "@angular/router";
import { of, Subscription } from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";

import { NgIf, NgFor, NgClass, SlicePipe } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronRight, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
    InstantSearchDocument,
    InstantSearchQuery,
    LogSearchResultClickDocument
} from "../../../common/gql/graphql";
import { DataService } from "../../providers/data/data.service";
import { AssetPreviewPipe } from "../../../shared/pipes/asset-preview.pipe";
import { SearchResultHighlightPipe } from "../../../shared/pipes/search-result-highlight.pipe";
import { PictureComponent } from "../../../shared/components/picture/picture.component";

type ProductResult = InstantSearchQuery['search']['items'][number] & { type: 'product' };
type ArticleResult = InstantSearchQuery['searchExternal'][number]['items'][number] & { type: 'article' };
type CollectionResult = InstantSearchQuery['searchExternal'][number]['items'][number] & {
    type: 'collection';
};

@Component({
    selector: 'vsf-product-search-bar',
    templateUrl: './product-search-bar.component.html',
    styleUrls: ['./product-search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FontAwesomeModule,
        ReactiveFormsModule,
        NgIf,
        NgFor,
        NgClass,
        RouterLink,
        PictureComponent,
        SlicePipe,
        SearchResultHighlightPipe,
        AssetPreviewPipe,
    ],
})
export class ProductSearchBarComponent implements OnInit, OnDestroy {
    /** If true, searches as you type */
    @Input() autoSearch = false;
    @Output() resultClick = new EventEmitter<void>();

    searchTerm = new UntypedFormControl('');
    instantSearchResults: Array<ProductResult | ArticleResult | CollectionResult> = [];
    showInstantSearchResults = false;
    activeIndex = -1;
    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
    private searchSubscription: Subscription;
    private routeSubscription: Subscription;
    private paramSubscription: Subscription;
    private queryId = '';
    search = faSearch;
    info = faInfo;
    chevronRight = faChevronRight;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.paramSubscription = this.route.queryParamMap.subscribe((qpm) => {
            const term = qpm.get('term');
            if (term) {
                this.searchTerm.setValue(term);
            }
        });

        this.routeSubscription = this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .subscribe((event) => {
                if (!event.url.startsWith('/search')) {
                    this.searchTerm.setValue('', { emitEvent: true });
                }
            });

        const searchResults$ = this.searchTerm.valueChanges.pipe(
            filter(() => this.autoSearch !== false),
            debounceTime(250),
            switchMap((term: string) => {
                const trimmed = term.trim();
                if (trimmed?.length) {
                    return this.dataService.query(InstantSearchDocument, {
                        input: {
                            groupByProduct: true,
                            term: trimmed,
                            take: 8,
                            prefixMode: true,
                            logAnalytics: true,
                        },
                        externalInput: {
                            term: trimmed,
                            take: 4,
                            prefixMode: true,
                            indexes: ['cms-articles', 'collections'],
                        },
                    });
                } else {
                    return of(undefined);
                }
            }),
        );

        this.searchSubscription = searchResults$.subscribe((data) => {
            this.instantSearchResults = [
                ...(data?.searchExternal[1]?.items ?? [])
                    .map((item) => ({
                        ...item,
                        type: 'collection' as const,
                    }))
                    .slice(0, 10 - (data?.search.items.length ?? 0)),
                ...(data?.search.items ?? []).map((item) => ({ ...item, type: 'product' as const })),
                ...(data?.searchExternal[0]?.items ?? [])
                    .map((item) => ({
                        ...item,
                        type: 'article' as const,
                    }))
                    .slice(0, 10 - (data?.search.items.length ?? 0)),
            ];
            this.activeIndex = -1;
            this.queryId = data?.search.queryId ?? '';
            this.changeDetector.markForCheck();
        });
    }

    trackByFn(index: number, item: ProductResult | ArticleResult) {
        return item.type === 'product' ? item.id : item.slug;
    }

    onFocus() {
        this.showInstantSearchResults = true;
        if (this.searchTerm.value) {
            this.inputElement.nativeElement.select();
        }
    }

    onBlur() {
        setTimeout(() => {
            this.showInstantSearchResults = false;
            this.changeDetector.markForCheck();
        }, 500);
    }

    onEnterKey(term: string) {
        const activeResult = this.instantSearchResults[this.activeIndex];
        if (activeResult?.type === 'product') {
            this.logResultClick(activeResult.sku, this.activeIndex + 1);
            this.router.navigate(['p', activeResult.slug]);
        } else if (activeResult?.type === 'article') {
            this.router.navigate(['a', activeResult.slug]);
        } else if (activeResult?.type === 'collection') {
            this.router.navigate(['c', activeResult.slug]);
        } else {
            this.showInstantSearchResults = false;
            this.inputElement.nativeElement.blur();
            this.router.navigate(['search'], {
                queryParams: { term },
                relativeTo: this.route,
            });
        }
        this.resultClick.next();
    }

    onSearchClick(term: string) {
        this.showInstantSearchResults = false;
        this.router.navigate(['search'], {
            queryParams: { term },
            relativeTo: this.route,
        });
    }

    onItemClick(item: ProductResult | ArticleResult | CollectionResult, index: number) {
        this.resultClick.next();
        if (item.type === 'product') {
            this.logResultClick(item.sku, index + 1);
        }
    }

    next(event: Event) {
        event.preventDefault();
        if (this.activeIndex < this.instantSearchResults.length - 1) {
            this.activeIndex++;
        } else {
            this.activeIndex = -1;
        }
    }

    prev(event: Event) {
        event.preventDefault();
        if (this.activeIndex === -1) {
            this.activeIndex = this.instantSearchResults.length - 1;
        } else {
            this.activeIndex--;
        }
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
        this.paramSubscription?.unsubscribe();
    }

    getProductAsset(item: ProductResult | ArticleResult): ProductResult['productAsset'] | undefined {
        return item.type === 'product' ? item.productAsset : undefined;
    }

    private logResultClick(sku: string, position: number) {
        if (this.queryId) {
            this.dataService
                .mutate(LogSearchResultClickDocument, {
                    input: {
                        queryId: this.queryId,
                        resultId: sku,
                        position,
                    },
                })
                .subscribe();
        }
    }
}
