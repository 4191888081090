<footer class="bg-plantation text-white" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-2 xl:mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div class="grid gap-y-6 xl:grid-cols-2 xl:gap-8">
            <div class="mt-8 xl:mt-0 grid sm:grid-cols-4 gap-10">
                <div class="flex justify-center">
                    <img
                        src="/assets/images/kbas-lettermark-with-text.svg"
                        alt="Bromleys Art Suppies Logo"
                        class="max-h-[167px]"
                    />
                </div>
                <div class="sm:col-span-3">
                    <h3 class="text-sm font-semibold text-gray-100 tracking-wider uppercase">
                        Subscribe to our newsletter
                    </h3>
                    <p class="mt-2 text-base text-gray-200">
                        The latest news, articles, and resources, sent to your inbox weekly.
                    </p>
                    <form class="mt-4 flex items-center sm:max-w-md">
                        <label for="mc_signup_input" class="sr-only">Email address</label>
                        <input
                            type="text"
                            name="mc_signup_input"
                            id="mc_signup_input"
                            class="input"
                            placeholder="Enter your email"
                            (focus)="openMailingListSignup()"
                        />
                        <div class="rounded-md ml-3 sm:flex-shrink-0">
                            <button class="btn btn-neutral" (click)="openMailingListSignup($event)">
                                Subscribe
                            </button>
                        </div>
                    </form>
                    <div class="flex space-x-6 md:order-2 mt-4 mb-8">
                        <a
                            href="https://www.facebook.com/BromleysArt"
                            target="_blank"
                            class="text-gray-100 hover:text-gray-200"
                        >
                            <span class="sr-only">Facebook</span>
                            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    fill-rule="evenodd"
                                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>

                        <a
                            href="https://www.instagram.com/bromleysart/"
                            target="_blank"
                            class="text-gray-100 hover:text-gray-200"
                        >
                            <span class="sr-only">Instagram</span>
                            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    fill-rule="evenodd"
                                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>

                        <a
                            href="https://twitter.com/BromleysArt"
                            target="_blank"
                            class="text-gray-100 hover:text-gray-200"
                        >
                            <span class="sr-only">Twitter</span>
                            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                                />
                            </svg>
                        </a>

                        <a
                            href="https://www.youtube.com/@BromleysArtSupplies"
                            target="_blank"
                            class="text-gray-100 hover:text-gray-200"
                        >
                            <span class="sr-only">YouTube</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="256"
                                height="180"
                                class="h-6 w-6"
                                viewBox="0 0 256 180"
                                fill="currentColor"
                            >
                                <path
                                    d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134"
                                />
                                <path fill="#24403d" d="m102.421 128.06l66.328-38.418l-66.328-38.418z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-3">
                <div *ngFor="let col of columnLinks">
                    <h3 class="text-sm font-semibold text-gray-100 tracking-wider uppercase">
                        {{ col.heading }}
                    </h3>
                    <ul class="mt-2 space-y-1 sm:space-y-2">
                        <li *ngFor="let link of col.links">
                            <a
                                *ngIf="link.route"
                                [routerLink]="link.route"
                                class="text-base text-gray-200 hover:text-gray-400"
                            >
                                {{ link.label }}
                            </a>
                            <a
                                *ngIf="link.url"
                                [href]="link.url"
                                class="text-base text-gray-200 hover:text-gray-400"
                            >
                                {{ link.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mt-8 border-t border-[#4e6965] pt-8">
            <div class="flex flex-wrap gap-8 items-center">
                <a
                    [routerLink]="['/a', 'privacy-policy']"
                    class="uppercase font-semibold text-sm text-gray-200 hover:text-gray-600"
                >
                    Privacy Policy
                </a>
                <a
                    [routerLink]="['/a', 'terms-conditions']"
                    class="uppercase font-semibold text-sm text-gray-200 hover:text-gray-600"
                >
                    Terms & Conditions
                </a>
                <div class="flex-grow"></div>
                <div class="flex gap-2" title="Accepted payment methods">
                    <img
                        src="/assets/icons/payment-mastercard.svg"
                        alt="Pay by Mastercard"
                        class="border rounded h-6 sm:h-8"
                    />
                    <img
                        src="/assets/icons/payment-visa.svg"
                        alt="Pay by Visa"
                        class="border rounded h-6 sm:h-8"
                    />
                    <img
                        src="/assets/icons/payment-maestro-alt.svg"
                        alt="Pay by Maestro"
                        class="border rounded h-6 sm:h-8"
                    />
                    <img
                        src="/assets/icons/payment-american-express.svg"
                        alt="Pay by American Express"
                        class="border rounded h-6 sm:h-8"
                    />
                    <img
                        src="/assets/icons/payment-paypal.svg"
                        alt="Pay by PayPal"
                        class="border rounded h-6 sm:h-8"
                    />
                    <img
                        src="/assets/icons/payment-google-pay.svg"
                        alt="Pay by Google Pay"
                        class="border rounded h-6 sm:h-8"
                    />
                </div>
                <div>
                    <kb-trustpilot-widget></kb-trustpilot-widget>
                </div>
            </div>
        </div>
        <div class="mt-8 border-t border-[#4e6965] pt-8 md:flex md:justify-between items-start">
            <div class="flex flex-col md:flex-row gap-8 justify-between w-full">
                <div class="max-w-xl flex-1 opacity-80">
                    At Bromleys we are artist ourselves, so know the best tools and materials on the market.
                    Supplying a vast range of art supplies at discount prices, with unbeatable service and
                    worldwide delievery for the last 30 years.
                </div>
                <div class="mt-8 text-sm text-gray-300 md:mt-0 md:order-1 opacity-70 flex-1 md:text-right">
                    <p>Ken Bromley Art Supplies Ltd. trading as Bromleys Art Supplies</p>
                    <p>Unit 13 Lodge Bank Estate, Crown Lane, Horwich, Bolton</p>
                    <p>BL6 5HY, United Kingdom</p>
                    <p>01204 690114, sales&#64;artsupplies.co.uk</p>
                    <p>Company Reg No. 2413062</p>
                </div>
            </div>
        </div>
    </div>
</footer>
