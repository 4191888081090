import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { BrowserTracing, init, routingInstrumentation } from "@sentry/angular-ivy";
import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app.config";

if (environment.sentryDsn) {
    init({
        dsn: environment.sentryDsn,
        environment: environment.sentryEnv,
        normalizeDepth: 5,
        integrations: [
            new BrowserTracing({
                tracePropagationTargets: ['localhost', 'https://api.artsupplies.co.uk/shop-api'],
                routingInstrumentation: routingInstrumentation,
            }),
        ],
        beforeBreadcrumb: (breadcrumb, hint) => {
            if (breadcrumb.category === 'xhr') {
                try {
                    const input = hint.input && JSON.parse(hint.input);
                    if (input?.operationName) {
                        breadcrumb.data.operationName = input.operationName;
                    }
                    if (input?.variables) {
                        breadcrumb.data.variables = input.variables;
                    }
                } catch (e: any) {
                    /* */
                }
            }
            return breadcrumb;
        },

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.01,
    });
}

if (environment.production) {
    enableProdMode();
}
bootstrapApplication(AppComponent, appConfig).catch((err) => {
    // tslint:disable:no-console
    console.log(err);
});
