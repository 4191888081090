import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ReferralLinkService } from '../core/providers/referral-link/referral-link.service';

/**
 * Handles referral links from the old website.
 */
@Injectable({ providedIn: 'root' })
export class LegacyReferralGuard  {
    constructor(private router: Router, private referralLinkService: ReferralLinkService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        this.referralLinkService.validateReferralCode(route.url[1].path);
        return this.router.parseUrl(`/?referralCode=${route.url[1].path}`);
    }
}
