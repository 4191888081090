import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";

import { environment } from "../../../../environments/environment";
import { PageMetadata } from "../../../common/types";

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    private readonly siteName: string;

    constructor(
        private title: Title,
        private meta: Meta,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.siteName = 'Bromleys Art Supplies';
        if (!environment.production) {
            this.siteName += ' (dev)';
        }
    }

    setSiteMetadata() {
        if (environment.sentryEnv !== 'production') {
            this.meta.addTag({ name: 'robots', content: 'noindex' });
        }
        this.meta.addTag({ name: 'og:site_name', content: this.siteName });
        this.meta.addTag({ name: 'og:type', content: 'website' });
        this.meta.addTag({ name: 'og:locale', content: 'en_GB' });
    }

    /**
     * Sets page-specific metadata
     */
    setPageMetadata(metadata?: PageMetadata) {
        const suffix = metadata?.includeGlobalSuffix !== false ? ` | ${this.siteName}` : '';
        const title = metadata?.title ? `${metadata.title}${suffix}` : this.siteName;
        const description = metadata?.description
            ? this.processDescription(metadata.description) ?? ''
            : metadata?.title ?? 'Art materials at discount prices';
        const image = metadata?.image
            ? metadata.image
            : 'https://www.artsupplies.co.uk/vendure-assets/og-image__preview.jpg?preset=full';

        this.title.setTitle(title);
        this.meta.updateTag({ name: 'og:title', content: title });
        if (description !== null) {
            this.meta.updateTag({ name: 'description', content: description });
            this.meta.updateTag({ name: 'og:description', content: description });
        }
        if (metadata?.image !== null) {
            this.meta.updateTag({ name: 'og:image', content: image });
        } else {
            this.meta.removeTag('name="og:image"');
        }
        if (metadata?.noIndex) {
            this.meta.addTag({ name: 'robots', content: 'noindex' });
        } else if (environment.sentryEnv === 'production') {
            this.meta.removeTag('name="robots"');
        }
        const canonicalUrl = metadata?.canonicalUrl != null ? this.getBaseUrl() + metadata.canonicalUrl : null;
        this.updateCanonicalUrl(canonicalUrl);
    }

    private processDescription(description: string): string {
        const sub = description.substr(0, 500);
        const sentences = sub.split(/\.\s*/).slice(0, 3).join('. ');
        return sentences.replace(/<\/?[^>]{1,5}>/g, '');
    }

    private getBaseUrl(): string {
        if (isPlatformBrowser(this.platformId)) {
            return window.location.origin.replace(/\/$/, '');
        } else {
            return (process.env['STOREFRONT_URL'] as string)?.replace(/\/$/, '');
        }
    }

    private updateCanonicalUrl(url: string | undefined | null) {
        const head = this.document.getElementsByTagName('head')[0];
        let element: HTMLLinkElement | null = this.document.querySelector(`link[rel='canonical']`) || null;
        if (element && url == null) {
            head.removeChild(element);
            return;
        }
        if (url != null) {
            if (element == null) {
                element = this.document.createElement('link') as HTMLLinkElement;
                head.appendChild(element);
            }
            element.setAttribute('rel', 'canonical');
            element.setAttribute('href', url);
        }
    }
}
