import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { InstantSearchQuery } from '../../common/gql/graphql';

type ProductResult = InstantSearchQuery['search']['items'][number] & { type: 'product' };
type ArticleResult = InstantSearchQuery['searchExternal'][number]['items'][number] & { type: 'article' };
type CollectionResult = InstantSearchQuery['searchExternal'][number]['items'][number] & {
    type: 'collection';
};

@Pipe({
    name: 'searchResultHighlight',
    standalone: true,
})
export class SearchResultHighlightPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    transform(value: ProductResult | ArticleResult | CollectionResult): SafeHtml {
        const prodHighlight = value.highlights.find((hl) => hl.field === 'productName');
        if (prodHighlight) {
            return this.domSanitizer.bypassSecurityTrustHtml(prodHighlight.snippet);
        }
        const variantHighlight = value.highlights.find((hl) => hl.field === 'productVariantName');
        if (variantHighlight) {
            return this.domSanitizer.bypassSecurityTrustHtml(variantHighlight.snippet);
        }
        const articleTitleHighlight = value.highlights.find((hl) => hl.field === 'title');
        if (articleTitleHighlight) {
            return this.domSanitizer.bypassSecurityTrustHtml(articleTitleHighlight.snippet);
        }
        return this.domSanitizer.bypassSecurityTrustHtml((value as any).productName ?? (value as any).title);
    }
}
