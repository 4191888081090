<div class="sm:relative flex-1">
    <div class="mt-1 relative rounded-md shadow">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <fa-icon [icon]="search" size="1x" class="text-gray-500"></fa-icon>
        </div>
        <input
            #inputElement
            type="text"
            autocomplete='off'
            class="focus:ring-primary-500 focus:border-primary-500 focus:bg-white block w-full pl-10 sm:text-sm border-gray-300 rounded transition-colors"
            [formControl]="searchTerm"
            (keydown.enter)="onEnterKey(searchTerm.value)"
            (keydown.arrowDown)="next($event)"
            (keydown.arrowUp)="prev($event)"
            (click)="activeIndex = -1"
            (focus)="onFocus()"
            (blur)="onBlur()"
            placeholder="Search for products or information"
            id="main-search-input"
            aria-label="Search input"
        />
        <button
            *ngIf="searchTerm.value"
            (click)="onSearchClick(searchTerm.value)"
            class="hidden md:block btn-icon absolute inset-y-0 right-0 text-sm px-2 m-1 py-0 bg-primary-100 text-primary-700 hover:bg-primary-200"
        >
            Search
        </button>
    </div>
    <div
        [class.hidden]="instantSearchResults.length === 0 || showInstantSearchResults === false"
        class="origin-top-right mt-0.5 bg-white z-30 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
    >
        <div
            class="py-1 rounded hover:bg-gray-100"
            role="none"
            *ngFor="let result of instantSearchResults; index as i; trackBy: trackByFn"
            [ngClass]="{ 'bg-gray-100 text-gray-900': i === activeIndex }"
        >
            <a
                href="#"
                class="text-gray-700 group flex items-center px-4 py-2 text-sm"
                role="menuitem"
                (click)="onItemClick(result, i)"
                [routerLink]="
                    result.type === 'product'
                        ? ['/p', result.slug]
                        : result.type === 'collection'
                        ? ['/c', result.slug]
                        : ['/a', result.slug]
                "
                tabindex="-1"
            >
                <kb-picture
                    class=''
                    *ngIf="result.type === 'product'"
                    [src]="getProductAsset(result) | assetPreview: 'tiny'"
                    [focalPoint]='getProductAsset(result)?.focalPoint'
                    [w]='50'
                    [h]='50'
                    imgClass="mr-2 rounded-sm"
                ></kb-picture>
                <fa-icon
                    *ngIf="result.type === 'article'"
                    [icon]="info"
                    size="sm"
                    class="text-blue-400 mr-4"
                ></fa-icon>
                <div>
                    <div
                        class="text-xs text-gray-400"
                        *ngIf="result.type === 'article' || result.type === 'collection'"
                    >
                        <ng-container *ngIf="result.breadcrumb.length > 1; else topLevel">
                            <span *ngFor="let part of result.breadcrumb | slice: -2:-1">{{ part }}</span>
                        </ng-container>
                        <ng-template #topLevel><span>Home</span></ng-template>
                        <fa-icon [icon]="chevronRight" size="sm" class="ml-1"></fa-icon>
                    </div>
                    <div [innerHTML]="result | searchResultHighlight"></div>
                </div>
            </a>
        </div>
    </div>
</div>
