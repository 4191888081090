import { HttpLink, Options } from 'apollo-angular/http';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { FactoryProvider, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { environment } from '../../environments/environment';
import possibleTypesResult from '../common/introspection-results';

const STATE_KEY = makeStateKey<any>('apollo.state');

export const APOLLO_CLIENT_PROVIDER: FactoryProvider = {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
    deps: [HttpLink, PLATFORM_ID, TransferState],
};

function mergeFields(existing: any, incoming: any) {
    return { ...existing, ...incoming };
}

function relaceFields(existing: any, incoming: any) {
    return incoming;
}

// Trying to debug why sessions won't work in Safari 13.1
// but only on the live prod version.
function logInterceptorData(on: boolean) {
    localStorage.setItem('_logInterceptorData', on ? 'true' : 'false');
}
if (typeof window !== 'undefined') {
    (window as any).logInterceptorData = logInterceptorData;
}

export function apolloOptionsFactory(
    httpLink: HttpLink,
    platformId: object,
    transferState: TransferState,
): ApolloClientOptions<any> {
    const AUTH_TOKEN_KEY = 'auth_token';
    const apolloCache = new InMemoryCache({
        possibleTypes: possibleTypesResult.possibleTypes,
        typePolicies: {
            Query: {
                fields: {
                    eligibleShippingMethods: {
                        merge: relaceFields,
                    },
                },
            },
            Product: {
                fields: {
                    customFields: {
                        merge: mergeFields,
                    },
                },
            },
            Collection: {
                fields: {
                    customFields: {
                        merge: mergeFields,
                    },
                },
            },
            Order: {
                fields: {
                    lines: {
                        merge: relaceFields,
                    },
                    shippingLines: {
                        merge: relaceFields,
                    },
                    discounts: {
                        merge: relaceFields,
                    },
                    shippingAddress: {
                        merge: relaceFields,
                    },
                    billingAddress: {
                        merge: relaceFields,
                    },
                },
            },
            Customer: {
                fields: {
                    addresses: {
                        merge: relaceFields,
                    },
                    customFields: {
                        merge: mergeFields,
                    },
                },
            },
        },
    });

    const { apiHost, apiPort, ssrApiHost, ssrApiPort, shopApiPath, ssrShopApiPath } = environment;
    const uri = isPlatformServer(platformId)
        ? `${ssrApiHost}:${ssrApiPort}/${ssrShopApiPath}`
        : `${apiHost}${apiPort !== 80 ? `:${apiPort}` : ''}/${shopApiPath}`;
    const options: Options = {
        uri,
        withCredentials: false,
    };

    const http = httpLink.create(options);
    const afterware = new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
            const context = operation.getContext();
            const log =
                typeof window != 'undefined' && localStorage.getItem('_logInterceptorData') === 'true';
            log && console.log(`context`, context);
            const authHeader = context.response.headers.get('vendure-auth-token');
            log && console.log(`authHeader`, authHeader);
            if (authHeader && isPlatformBrowser(platformId)) {
                // If the auth token has been returned by the Vendure
                // server, we store it in localStorage
                localStorage.setItem(AUTH_TOKEN_KEY, authHeader);
                log && console.log(`localStorage.setItem(AUTH_TOKEN_KEY, authHeader);`, authHeader);
            }
            return response;
        });
    });
    function x() {
        return btoa(btoa(btoa(new Date().toISOString())));
    }
    const middleware = new ApolloLink((operation, forward) => {
        let headers = new HttpHeaders().set('x-verify', x());
        if (isPlatformBrowser(platformId)) {
            headers = headers.set('Authorization', `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY) || null}`);
        }
        operation.setContext({
            headers,
        });
        return forward(operation);
    });

    const isBrowser = transferState.hasKey<any>(STATE_KEY);

    if (isBrowser) {
        const state = transferState.get<any>(STATE_KEY, null);
        apolloCache.restore(state);
    } else {
        transferState.onSerialize(STATE_KEY, () => {
            return apolloCache.extract();
        });
        // Reset apolloCache after extraction to avoid sharing between requests
        apolloCache.reset();
    }

    return {
        cache: apolloCache,
        ssrMode: true,
        ssrForceFetchDelay: 500,
        link: ApolloLink.from([middleware, afterware, http]),
    };
}
