import { Route } from '@angular/router';
import { RouteNotFoundComponent } from './core/components/route-not-found/route-not-found.component';
import { LegacyArticleGuard } from './routing/legacy-article-guard';
import { RedirectGuard } from './routing/redirect-guard';
import { LegacyProductGuard } from './routing/legacy-product-guard';
import { LegacyCategoryGuard } from './routing/legacy-category-guard';
import { LegacyReferralGuard } from './routing/legacy-referral-guard';
import { LEGACY_CAT_URL_RE, LEGACY_INFO_URL_RE, LEGACY_ITEM_URL_RE } from "./routing/redirect-shared";

export const routes: Route[] = [
    {
        path: '',
        loadChildren: () => import('./home/home.routes').then((mod) => mod.routes),
        pathMatch: 'full',
        data: { revalidate: 5 },
    },
    {
        path: '',
        loadChildren: () => import('./catalog/catalog.routes').then((mod) => mod.routes),
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.routes').then((mod) => mod.routes),
    },
    {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.routes').then((mod) => mod.routes),
    },
    {
        path: 'wishlist',
        loadChildren: () => import('./wishlist/wishlist.routes').then((mod) => mod.routes),
    },
    {
        path: 'mailing-list',
        loadChildren: () => import('./mailing-list/mailing-list.routes').then((mod) => mod.routes),
    },
    {
        path: 'a',
        loadChildren: () => import('./article/article.routes').then((mod) => mod.routes),
    },
    {
        matcher: (segments) => {
            const match = segments.length === 1 && segments[0].path.match(LEGACY_INFO_URL_RE);
            return match ? { consumed: segments } : null;
        },
        component: RouteNotFoundComponent,
        canActivate: [LegacyArticleGuard],
    },
    {
        matcher: (segments) => {
            const match = segments.length === 1 && segments[0].path.match(LEGACY_ITEM_URL_RE);
            return match ? { consumed: segments } : null;
        },
        component: RouteNotFoundComponent,
        canActivate: [LegacyProductGuard],
    },
    {
        matcher: (segments) => {
            const match = segments.length === 1 && segments[0].path.match(LEGACY_CAT_URL_RE);
            return match ? { consumed: segments } : null;
        },
        component: RouteNotFoundComponent,
        canActivate: [LegacyCategoryGuard],
    },
    {
        matcher: (segments) => {
            const match = segments.length === 2 && segments[0].path === 'referral';
            return match ? { consumed: segments } : null;
        },
        component: RouteNotFoundComponent,
        canActivate: [LegacyReferralGuard],
    },
    {
        path: '**',
        component: RouteNotFoundComponent,
        canActivate: [RedirectGuard],
        data: { revalidate: null },
    },
];
