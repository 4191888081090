import { ChangeDetectionStrategy, Component } from "@angular/core";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { StateService } from "../../providers/state.service";

@Component({
    selector: 'kb-mobile-menu-toggle',
    templateUrl: './mobile-menu-toggle.component.html',
    styleUrls: ['./mobile-menu-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FontAwesomeModule],
})
export class MobileMenuToggleComponent {
    bars = faBars;
    constructor(private stateService: StateService) {}

    toggle() {
        this.stateService.setState('mobileNavMenuIsOpen', true);
    }
}
