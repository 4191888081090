import { FocusMonitor, A11yModule } from '@angular/cdk/a11y';
import { Overlay, OverlayConfig, OverlayRef, ViewportRuler } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT, NgIf, NgFor, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { filter, merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GetMenuItemsQuery } from '../../../common/gql/graphql';
import { DataService } from '../../providers/data/data.service';

import { arrayToTree, RootNode, TreeNode } from './array-to-tree';
import { CollectionsMenuPositionStrategy } from './collections-menu-position-strategy';
import { CollectionItem, MenuItem } from './collections-menu-types';
import { CanonicalCollectionSlugPipe } from '../../../shared/pipes/canonical-collection-slug.pipe';
import { AssetPreviewPipe } from '../../../shared/pipes/asset-preview.pipe';
import { PictureComponent } from '../../../shared/components/picture/picture.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'vsf-collections-menu',
    templateUrl: './collections-menu.component.html',
    styleUrls: ['./collections-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        RouterLink,
        A11yModule,
        PictureComponent,
        AssetPreviewPipe,
        CanonicalCollectionSlugPipe,
    ],
})
export class CollectionsMenuComponent implements OnInit, OnChanges, OnDestroy {
    private readonly MENU_CLOSE_DELAY_MS = 300;
    @Input() disabled = false;
    @Input() collections: GetMenuItemsQuery['menuItems'];
    collectionTree: RootNode<CollectionItem>;
    activeCollection: TreeNode<CollectionItem> | null;
    activeCollectionFeatures: GetMenuItemsQuery['menuItems'][number]['customFields']['featured'] = [];
    menuItems: MenuItem[] = [];
    @ViewChild('menuTemplate', { read: TemplateRef }) menuTemplate: TemplateRef<any>;
    currentMonth = new Date().getMonth();

    private closeFn: (() => any) | null = null;
    private setActiveCollection$ = new Subject<{
        collection: TreeNode<CollectionItem>;
        element: HTMLElement;
    } | null>();
    private destroy$ = new Subject();
    private overlayRef: OverlayRef | undefined;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private dataService: DataService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private changeDetector: ChangeDetectorRef,
        private focusMonitor: FocusMonitor,
        private viewportRuler: ViewportRuler,
    ) {}

    ngOnInit() {
        const open$ = this.setActiveCollection$.pipe(
            distinctUntilChanged((a, b) => a?.element === b?.element),
            debounceTime(200),
            filter((val) => !!val),
        );
        const close$ = this.setActiveCollection$.pipe(
            debounceTime(this.MENU_CLOSE_DELAY_MS),
            filter((val) => val == null),
        );

        merge(open$, close$)
            .pipe(takeUntil(this.destroy$))
            .subscribe((val) => {
                this.activeCollection = val?.collection;
                this.activeCollectionFeatures =
                    this.activeCollection?.customFields.featured
                        .slice(0)
                        .sort((a, b) => b.weight - a.weight)
                        .map((feature) => {
                            const matches = feature.url.match(/(\/[pca]\/.+$)/);
                            return { ...feature, url: matches?.length ? matches[0] : feature.url };
                        }) ?? [];
                if (this.activeCollection) {
                    this.menuItems = this.flattenMenuItems(this.activeCollection);
                }
                if (val) {
                    this.openOverlay(val.element);
                } else {
                    this.closeOverlay();
                }
                this.changeDetector.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('collections' in changes && Array.isArray(this.collections)) {
            this.collectionTree = arrayToTree(this.collections);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    onTopLevelClick(event: Event, collection: TreeNode<CollectionItem>) {
        if (collection.children.length) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.setActive(collection, event);
            this.registerDocumentTouchHandler();
        } else {
            this.closeOverlay();
        }
    }

    captureTouchStart(event: TouchEvent) {
        event.stopPropagation();
    }

    setActive(collection: TreeNode<CollectionItem>, event: Event) {
        const element = event.target as HTMLElement;
        this.setActiveCollection$.next(collection ? { collection, element } : null);
    }

    setActiveWithKeyboard(event: Event, collection: TreeNode<CollectionItem>) {
        if (collection.children.length === 0) {
            // If no child collection, let the click activate a navigation
            return;
        }
        event.preventDefault();
        this.setActive(collection, event);
        if (this.document) {
            setTimeout(() => {
                const dropDownFirstLink = this.document.querySelector(
                    '#collectionDropdown a',
                ) as HTMLAnchorElement;
                if (dropDownFirstLink) {
                    this.focusMonitor.focusVia(dropDownFirstLink, 'keyboard');
                }
            }, 150);
        }
    }

    linkClick() {
        this.close();
    }

    close() {
        this.setActiveCollection$.next(null);
    }

    dropDownBgClick(event: MouseEvent) {
        const target = event.target;
        if (target instanceof HTMLDivElement) {
            if (target.id === 'collectionDropdown') {
                this.close();
            }
        }
    }

    private flattenMenuItems(collection: TreeNode<CollectionItem>): MenuItem[] {
        const ROW_COUNT = 23;
        const result: MenuItem[] = [];
        let itemsCount = 1;
        if (collection.slug === 'brands') {
            result.push({ level: 0, collection });
            for (const child of collection.children.slice(0, 40).sort((a, b) => (a.name < b.name ? -1 : 1))) {
                result.push({ level: 1, collection: child });
                itemsCount++;
            }
            result.push({ level: 0, collection: { ...collection, name: 'View all brands...' } });
        } else {
            for (const child of collection.children) {
                const remainingSlotsInColumn = ROW_COUNT - ((itemsCount - 1) % ROW_COUNT);
                const requiredSlots = child.children.length + 1;
                if (remainingSlotsInColumn < requiredSlots) {
                    // The sub-collections list no longer fits in this column without
                    // wrapping, so we instead fill the remaining slots with whitespace.
                    for (let i = 0; i < remainingSlotsInColumn; i++) {
                        result.push({ level: 0 });
                        itemsCount++;
                    }
                }
                result.push({ level: 0, collection: child });
                itemsCount++;
                for (const grandchild of child.children) {
                    result.push({ level: 1, collection: grandchild });
                    itemsCount++;
                }
                // Prevent a space as the first item of a new column
                if (itemsCount % ROW_COUNT !== 1) {
                    result.push({ level: 0 });
                    itemsCount++;
                }
            }
        }
        return result;
    }

    private openOverlay(element: HTMLElement) {
        if (this.overlayRef) {
            if (element.tagName === 'LI') {
                this.overlayRef.updatePositionStrategy(this.getPositionStrategy(element));
                this.overlayRef.updatePosition();
            }
            return;
        }
        const positionStrategy = this.getPositionStrategy(element);
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        this.overlayRef = this.overlay.create(
            new OverlayConfig({
                scrollStrategy,
                positionStrategy,
                maxHeight: 700,
                maxWidth: '1280px',
                hasBackdrop: true,
                backdropClass: ['bg-[#1d232866]'],
            }),
        );
        this.overlayRef.attach(new TemplatePortal(this.menuTemplate, this.viewContainerRef));
        const layoutHeaderEl = this.document.querySelector('vsf-layout-header') as HTMLDivElement;

        const positionBackdrop = () => {
            const yOffset = layoutHeaderEl.classList.contains('floating') ? window.scrollY : 0;
            this.overlayRef.backdropElement.style.marginTop =
                layoutHeaderEl.getBoundingClientRect().bottom + yOffset + 'px';
        };
        window.addEventListener('scroll', positionBackdrop, { passive: true });
        positionBackdrop();
        this.closeFn = () => {
            window.removeEventListener('scroll', positionBackdrop);
            this.overlayRef.dispose();
            this.closeFn = null;
            this.overlayRef = undefined;
        };
    }

    private getPositionStrategy(element: HTMLElement) {
        const contentContainer = this.document.querySelector('#content-container') as HTMLElement;
        if (!contentContainer) {
            throw new Error('Could not find content container element');
        }
        return new CollectionsMenuPositionStrategy(
            this.menuItems,
            contentContainer,
            element,
            this.viewportRuler,
        );
    }

    private closeOverlay() {
        if (typeof this.closeFn === 'function') {
            this.closeFn();
        }
    }

    private registerDocumentTouchHandler = () => {
        const handler = () => {
            this.closeOverlay();
            this.document.removeEventListener('touchstart', handler);
        };
        this.document.addEventListener('touchstart', handler, { passive: true });
    };
}
