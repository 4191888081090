<ng-template vsfDialogTitle>Add these items to your cart?</ng-template>
<p class='text-gray-600'>
    Last time you were signed in, you had the following products saved in your cart. Select any you wish to
    add to this order.
</p>
<div class='divide-y my-8'>
    <div class='flex py-4' *ngFor='let line of addedOrderLines'>
        <div class='mr-4'>
            <div class='relative'>
                <img
                    alt='Product thumbnail'
                    class='rounded-lg border border-gray-50 shadow-sm'
                    [src]="line.featuredAsset?.preview + '?preset=tiny'"
                />
            </div>
        </div>
        <div class='flex-1'>
            <div class='font-medium text-gray-700 text-sm'>
                {{ line.productVariant?.name }}
            </div>
            <div class='text-sm text-gray-600' *ngIf='line.customFields.addedFromWishlistCustomerName'>
                from {{ line.customFields.addedFromWishlistCustomerName }}'s wishlist
            </div>
            <div class='text-sm text-gray-600' *ngIf='line.giftCardInput?.recipientEmailAddress'>
                to: {{ line.giftCardInput.recipientEmailAddress }}
            </div>
            <div class='flex items-baseline space-x-4 text-sm mt-1'>
                <div class='text-center'>
                    x {{ line.quantity }}
                </div>
                <div class='text-gray-600 font-medium'>{{ line.linePriceWithTax | formatPrice }}</div>
                <div class='flex-1'></div>
                <div class='relative flex items-start'>
                    <div class='flex items-center h-5'>
                        <input [id]='"comments-" + line.id' aria-describedby='comments-description'
                               [name]='"comments-" + line.id' type='checkbox'
                               [checked]='isSelected(line.id)'
                               (change)='toggleKeepLine(line.id)'
                               class='focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded'>
                    </div>
                    <div class='ml-3 text-sm'>
                        <label [for]='"comments-" + line.id' class='font-medium text-gray-700'>Add this to my current
                            order</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template vsfDialogButtons>
    <button (click)='toggleAll()' class='text-sm mr-4'>
        <kb-checkbox
            [isChecked]='lineIdsToKeep.length === addedOrderLines.length'
        ></kb-checkbox>
        Select all
    </button>
    <button type='button' class='btn btn-primary' (click)='close()'>
        <span *ngIf='lineIdsToKeep.length === 1'>
            Add 1 selected line
        </span>
        <span *ngIf='1 < lineIdsToKeep.length'>
            Add {{ lineIdsToKeep.length }} selected lines
        </span>
        <span *ngIf='lineIdsToKeep.length === 0'>
            Don't add anything
        </span>
    </button>
</ng-template>
