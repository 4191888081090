import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TraceService } from '@sentry/angular-ivy';
import {
    Event,
    provideRouter,
    Router,
    Scroll,
    UrlSerializer,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
} from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from './environments/environment';
import { CoreModule } from './app/core/core.module';
import { ChunkLoadErrorHandler } from './app/chunk-load-error-handler';
import { MetadataService } from './app/core/providers/metadata/metadata.service';
import { CustomUrlSerializer } from './app/common/custom-url-serializer';
import { routes } from './app/app.routes';
import { DotdigitalService } from './app/core/providers/dotdigital/dotdigital.service';

export const SHARED_PROVIDERS: ApplicationConfig['providers'] = [
    { provide: ErrorHandler, useClass: ChunkLoadErrorHandler },
    {
        provide: TraceService,
        deps: [Router],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => {
            /**/
        },
        deps: [TraceService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory:
            (metadataService: MetadataService, router: Router, viewportScroller: ViewportScroller) => () => {
                metadataService.setSiteMetadata();
                router.events.pipe(filter((e: Event): e is Scroll => e instanceof Scroll)).subscribe((e) => {
                    if (e.position) {
                        // backward navigation
                        viewportScroller.scrollToPosition(e.position);
                    } else if (e.anchor) {
                        // anchor navigation
                        viewportScroller.scrollToAnchor(e.anchor);
                    } else {
                        // forward navigation
                        const nav = router.lastSuccessfulNavigation;
                        if (nav?.extras.state?.noScroll !== true) {
                            viewportScroller.scrollToPosition([0, 0]);
                        }
                    }
                });
            },
        deps: [MetadataService, Router, ViewportScroller],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (dotdigitalService: DotdigitalService) => () => {
            dotdigitalService.setUpTracking();
        },
        deps: [DotdigitalService],
        multi: true,
    },
    provideClientHydration(),
    {
        provide: UrlSerializer,
        useClass: CustomUrlSerializer,
    },
    provideRouter(
        routes,
        withEnabledBlockingInitialNavigation(),
        withInMemoryScrolling({ scrollPositionRestoration: 'disabled' }),
        withComponentInputBinding(),
    ),
];

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            BrowserModule,
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
            CoreModule,
        ),
        ...SHARED_PROVIDERS,
    ],
};
