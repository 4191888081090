<button class="btn-icon relative block" (click)="toggle.emit()" title="cart">
    <fa-icon [icon]="shoppingCart" class="mr-1"></fa-icon>
    <div *ngIf="cart$ | async as cart">
        <span
            class="text-sm absolute -top-1.5 -right-1.5 bg-primary-800 bg-opacity-80 text-white rounded-full w-5 h-5 flex items-center justify-center"
            [ngClass]="{ hidden: cart.quantity === 0, block: cart.quantity > 0 }"
            >{{ cart.quantity }}</span
        >
    </div>
</button>
