export const LEGACY_CAT_URL_RE = /^cat-(.+)\.htm/;
export const LEGACY_ITEM_URL_RE = /^(?:item|colours|brushes)-(.+)\.htm/;
export const LEGACY_INFO_URL_RE = /^info-(.+)\.htm/;

export function getProductSlugFromLegacyPath(path?: string) {
    return getSlugFromLegacyPath(LEGACY_ITEM_URL_RE, path)
        .replace(/-\d+ml(-(tubes|bottles|pot|tubs))?$/, "")
        .replace(/-(half-pans?|whole-pans?)$/, "");

}

export function getSlugFromLegacyPath(re: RegExp, path?: string): string {
    const matches = (path ?? "").match(re);
    return (matches && 1 < matches.length ? matches[1] : "")
        .replace(/,/g, "")
        .replace(/-&/g, "")
        .replace(/&/g, "");

}
