import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RouterLink } from "@angular/router";
import { NgIf, AsyncPipe, SlicePipe } from "@angular/common";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { ActiveService } from "../../providers/active-order/active.service";
import { ActiveCustomerFragment } from "../../../common/gql/graphql";

@Component({
    selector: 'vsf-account-link',
    templateUrl: './account-link.component.html',
    styleUrls: ['./account-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        FontAwesomeModule,
        AsyncPipe,
        SlicePipe,
    ],
})
export class AccountLinkComponent implements OnInit {
    activeCustomer$: Observable<ActiveCustomerFragment | undefined>;
    userCircle = faUserCircle;
    constructor(private activeService: ActiveService) {}

    ngOnInit() {
        this.activeCustomer$ = this.activeService.activeCustomer$;
    }
}
