<div class="relative w-screen overflow-x-hidden">
    <div class="menu-frame flex transition-transform" [ngClass]="'active-level-' + activeLevel" #menuFrame>
        <div class="w-screen">
            <ul class="w-full">
                <li
                    class="px-4 py-3 flex justify-between items-center cursor-pointer hover:bg-gray-100"
                    *ngFor="let collection of level0Collections"
                    (click)="onL0Click($event, collection)"
                >
                    <div class="flex items-center space-x-4">
                        <kb-picture
                            [lazy]="true"
                            [src]="collection.featuredAsset?.preview"
                            [focalPoint]="collection.featuredAsset?.focalPoint"
                            preset="tiny"
                            [w]="50"
                            [h]="50"
                            imgClass="rounded"
                        ></kb-picture>
                        <div
                            [ngClass]="{
                                'px-3 py-1 text-white shadow rounded bg-gradient-to-r from-gray-700 to-gray-900': collection.slug.includes('black-friday')
                            }"
                        >
                            {{ collection.name }}
                        </div>
                    </div>
                    <fa-icon
                        [icon]="chevronRight"
                        class="text-gray-600 mr-2"
                        *ngIf="collection.children.length"
                    ></fa-icon>
                </li>
            </ul>
        </div>
        <div class="w-screen">
            <button
                class="px-4 py-4 block w-full text-left cursor-pointer hover:bg-gray-100"
                (click)="back()"
            >
                <fa-icon [icon]="chevronLeft" class="mr-2 text-gray-600"></fa-icon>
                {{ parent?.name }}
            </button>
            <ul class="w-full">
                <li
                    class="px-4 py-3 flex justify-between items-center cursor-pointer hover:bg-gray-100"
                    *ngFor="let collection of level1Collections"
                    (click)="onL1Click($event, collection)"
                >
                    <div class="flex items-center space-x-4">
                        <kb-picture
                            [lazy]="true"
                            [src]="collection.featuredAsset?.preview"
                            [focalPoint]="collection.featuredAsset?.focalPoint"
                            preset="tiny"
                            [w]="50"
                            [h]="50"
                            imgClass="rounded"
                        ></kb-picture>
                        <div>{{ collection.name }}</div>
                    </div>
                    <fa-icon
                        [icon]="chevronRight"
                        class="text-gray-600 mr-2"
                        *ngIf="collection.children.length"
                    ></fa-icon>
                </li>
            </ul>
        </div>
        <div class="w-screen">
            <button
                class="px-4 py-4 block w-full text-left cursor-pointer hover:bg-gray-100"
                (click)="back()"
            >
                <fa-icon [icon]="chevronLeft" class="mr-2 text-gray-600"></fa-icon>
                {{ parent?.name }}
            </button>
            <ul class="w-full">
                <li
                    class="px-4 py-4 flex justify-between items-center cursor-pointer hover:bg-gray-100"
                    *ngFor="let collection of level2Collections"
                >
                    <div class="flex items-center space-x-4">
                        <kb-picture
                            [lazy]="true"
                            [src]="collection.featuredAsset?.preview"
                            [focalPoint]="collection.featuredAsset?.focalPoint"
                            preset="tiny"
                            [w]="50"
                            [h]="50"
                            imgClass="rounded"
                        ></kb-picture>
                        <div class="" [routerLink]="['/c/', collection.slug]" (click)="close()">
                            {{ collection.name }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
