<div class="flex flex-col max-h-screen overflow-y-auto h-full" #outer>
    <div class="flex flex-col mb-12" #inner>
        <vsf-cart-contents
            [lazyLoadImages]="true"
            *ngIf="!(isEmpty$ | async); else empty"
            class="mx-4 mt-4"
            [cart]="cart$ | async"
            [canAdjustQuantities]="canModifyCart$ | async"
        ></vsf-cart-contents>
        <ng-template #empty>
            <div class="text-center flex flex-col items-center justify-center">
                @if (isLoading$ | async) {
                    <kb-spinner classes="mt-20 w-24 h-24 fill-gray-300" />
                } @else {
                    <div class="mt-32">
                        <fa-icon [icon]="shoppingCart" size="4x" class="text-gray-300"></fa-icon>
                    </div>
                    <div class="w-full flex items-center justify-center text-gray-400 text-3xl h-32">
                        Cart is empty
                    </div>
                }
            </div>
        </ng-template>
        <div class="my-6 px-2 sm:px-6 lg:px-8 flex justify-center">
            <kb-cart-coupons [activeCouponCodes]="activeCouponCodes$ | async"></kb-cart-coupons>
        </div>
        <kb-free-shipping-notice
            [cart]="cart$ | async"
            [fetchOnCartDrawerOpen]="true"
            class="block sm:px-6 lg:px-8 mb-2"
        />
        <kb-cart-totals
            [cart]="cart$ | async"
            [canSetShipping]="true"
            class="block sm:px-6 lg:px-8"
        />
        <div class="sm:px-6 lg:px-8">
            <a
                class="btn btn-primary block mt-6 text-center mx-4"
                color="primary"
                (click)="checkoutClick()"
                *ngIf="(isEmpty$ | async) === false"
                [routerLink]="['/checkout']"
                >Checkout</a
            >
        </div>
    </div>
</div>
