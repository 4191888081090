import { NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { GetMenuItemsQuery } from '../../../common/gql/graphql';
import { StateService } from '../../providers/state.service';
import { arrayToTree, TreeNode } from '../collections-menu/array-to-tree';
import { PictureComponent } from '../../../shared/components/picture/picture.component';

type CollectionItem = GetMenuItemsQuery['menuItems'][number];

@Component({
    selector: 'kb-collections-menu-mobile',
    templateUrl: './collections-menu-mobile.component.html',
    styleUrls: ['./collections-menu-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [NgClass, NgFor, PictureComponent, NgIf, FontAwesomeModule, RouterLink],
})
export class CollectionsMenuMobileComponent implements OnChanges {
    @Input() collections: GetMenuItemsQuery['menuItems'] = [];
    level0Collections: Array<TreeNode<CollectionItem>> = [];
    level1Collections: Array<TreeNode<CollectionItem>> = [];
    level2Collections: Array<TreeNode<CollectionItem>> = [];
    parent: CollectionItem | undefined;
    activeLevel: 0 | 1 | 2 = 0;
    chevronRight = faChevronRight;
    chevronLeft = faChevronLeft;

    @ViewChild('menuFrame') menuFrameRef: ElementRef<HTMLDivElement>;

    constructor(private router: Router, private stateService: StateService) {}

    ngOnChanges(changes: SimpleChanges) {
        if ('collections' in changes && Array.isArray(this.collections)) {
            this.level0Collections = arrayToTree(this.collections).children;
        }
    }

    onL0Click(event: Event, collection: TreeNode<CollectionItem>) {
        this.expandOrNavigate(0, event, collection);
    }

    onL1Click(event: Event, collection: TreeNode<CollectionItem>) {
        this.expandOrNavigate(1, event, collection);
    }

    back() {
        this.activeLevel--;
        this.parent = this.parent?.parent as any;
    }

    close() {
        this.stateService.setState('mobileNavMenuIsOpen', false);
    }

    private expandOrNavigate(level: 0 | 1, event: Event, collection: TreeNode<CollectionItem>) {
        if (collection.children.length) {
            this.parent = collection;
            if (level === 0) {
                this.level1Collections = collection.children;
                this.activeLevel = 1;
            } else {
                this.level2Collections = collection.children;
                this.activeLevel = 2;
            }
            event.preventDefault();
            event.stopImmediatePropagation();
            if (this.menuFrameRef) {
                this.menuFrameRef.nativeElement.scrollIntoView?.();
            }
        } else {
            this.router.navigate(['/c/', collection.slug]);
            this.close();
        }
    }
}
