import { PositionStrategy, ViewportRuler, OverlayRef } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { MenuItem } from './collections-menu-types';

export class CollectionsMenuPositionStrategy implements PositionStrategy {
    private resizeSubscription: Subscription;
    private overlayRef: OverlayRef;

    constructor(
        private menuItems: MenuItem[],
        private containerElement: HTMLElement,
        private triggerElement: HTMLElement,
        private viewportRuler: ViewportRuler,
    ) {}

    apply(): void {
        const containerRect = this.containerElement.getBoundingClientRect();
        const triggerRect = this.triggerElement.getBoundingClientRect();
        const triggerMidpoint = Math.round(triggerRect.left + triggerRect.width / 2);
        const overlayWidth = Math.ceil(this.menuItems.length / 23) * 256 + 64 + 140;
        const pos: { top: number; left: number } = { top: 0, left: 0 };

        const overlapsLeft = containerRect.left > triggerMidpoint - overlayWidth / 2;
        const overlapsRight = containerRect.right < triggerMidpoint + overlayWidth / 2;
        if (!overlapsLeft && !overlapsRight) {
            pos.left = triggerMidpoint - overlayWidth / 2;
        } else if (overlapsRight) {
            pos.left = containerRect.right - overlayWidth;
        } else {
            pos.left = containerRect.left;
        }

        pos.top = triggerRect.bottom + 5;

        this.overlayRef.overlayElement.style.top = `${pos.top}px`;
        this.overlayRef.overlayElement.style.left = `${pos.left}px`;
    }

    attach(overlayRef: OverlayRef): void {
        this.overlayRef = overlayRef;

        this.resizeSubscription = this.viewportRuler.change().subscribe(() => {
            this.apply();
        });
    }

    detach(): void {
        /* noop */
    }

    dispose(): void {
        this.resizeSubscription?.unsubscribe();
    }
}
