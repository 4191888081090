<vsf-dropdown [position]="['bottom-right']" [closeOnItemClick]="true" *ngIf='activeCurrency'>
    <div vsfDropdownTrigger>
        <button class="group inline-flex justify-center">
            <div class="mr-1">
                <fa-icon [icon]="activeCurrency.icon" class="mr-1"></fa-icon>
                <span>{{ activeCurrency.code }}</span>
            </div>
            <fa-icon [icon]="chevronDown" class=""></fa-icon>
        </button>
    </div>
    <div class="divide-y divide-gray-200" vsfDropdownContent>
        <button
            *ngFor="let item of availableCurrencies"
            (click)="setCurrency(item.code)"
            class="block w-full text-left text-gray-500 hover:bg-gray-50 hover:text-gray-900 block px-4 py-2 text-sm"
        >
            <fa-icon [icon]="item.icon" class="mr-1"></fa-icon>
            <span>{{ item.code }}</span>
        </button>
    </div>
</vsf-dropdown>
