import { Component } from "@angular/core";
import { Dialog } from "../../../../core/providers/modal/modal-types";
import { ActiveService } from "../../../../core/providers/active-order/active.service";
import { DataService } from "../../../../core/providers/data/data.service";
import { RouterLink } from "@angular/router";

@Component({
  selector: 'kb-mailing-list-popup',
  standalone: true,
    imports: [
        RouterLink
    ],
  templateUrl: './mailing-list-popup.component.html',
  styleUrl: './mailing-list-popup.component.css'
})
export class MailingListPopupComponent  implements Dialog<boolean> {
    resolveWith: (result: boolean | undefined) => void;

    constructor(private activeService: ActiveService, private dataService: DataService) {
    }
}
