<div class="h-full w-full bg-cover bg-[-45px] md:bg-left" style="background-image: url('/assets/images/email-fullscreen-bg.webp');">
    <div class="max-w-2xl bottom-[4vh] md:bottom-20 lg:bottom-[7vh] pl-[40px] md:pl-[110px] lg:pl-[160px] text-white absolute">
        <div class="text-xl md:text-3xl lg:text-5xl uppercase font-bold">Enjoy £5 off</div>
        <div class="text-xl md:text-3xl lg:text-5xl my-1 lg:mt-1 lg:mb-4">your next order<sup>*</sup></div>
        <div class="mt-2 lg:mt-4">
            <a [routerLink]="['/mailing-list']" (click)="resolveWith(true)" class="btn btn-primary inline-block lg:text-2xl">Yes please</a>
        </div>
        <div class="mt-[2vh] lg:mt-[14vh] text-sm"><sup>*</sup> applies to new subscribers to our email list</div>
    </div>
</div>
