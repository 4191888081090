import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from, Observable, timer, zip } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ActiveService } from '../../providers/active-order/active.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'vsf-cart-toggle',
    templateUrl: './cart-toggle.component.html',
    styleUrls: ['./cart-toggle.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FontAwesomeModule,
        NgClass,
        AsyncPipe,
    ],
})
export class CartToggleComponent implements OnInit {
    @Output() toggle = new EventEmitter<void>();
    cart$: Observable<{ total: number; quantity: number }>;
    cartChangeIndication$: Observable<boolean>;
    shoppingCart = faShoppingCart;

    constructor(private activeService: ActiveService) {}

    ngOnInit() {
        this.cart$ = this.activeService.activeOrder$.pipe(
            map((activeOrder) => {
                return {
                    total: activeOrder?.total ?? 0,
                    quantity: activeOrder?.lines.reduce((qty, line) => qty + line.quantity, 0) ?? 0,
                };
            }),
        );
        this.cartChangeIndication$ = this.cart$.pipe(
            map((cart) => cart.quantity),
            distinctUntilChanged(),
            switchMap(() => zip(from([true, false]), timer(0, 1000), (val, num) => val)),
        );
    }
}
