<div class="">
    <ul class="flex flex-wrap justify-center" *ngIf="collectionTree as tree">
        <li
            class="mx-1.5 xl:mx-2 my-2 px-2 rounded"
            *ngFor="let collection of tree.children"
            [ngClass]="{
                'bg-gray-50': activeCollection?.id === collection.id,
                'black-friday shadow': collection.slug.includes('black-friday'),
                'xmas-gifts shadow': collection.slug.includes('gifts') && currentMonth >= 10,
            }"
            (mouseenter)="setActive(collection, $event)"
            (keydown.enter)="setActiveWithKeyboard($event, collection)"
            (click)="close()"
            (mouseleave)="close()"
        >
            <a
                class="text-sm font-medium uppercase text-center block"
                [ngClass]="activeCollection?.id === collection.id ? 'text-primary-600' : 'text-primary-900'"
                (touchstart)="onTopLevelClick($event, collection)"
                [routerLink]="['/c', collection.slug | canonicalCollectionSlug]"
                >{{ collection.name }}</a
            >
            <div class="sub-collections">
                <ng-container *ngFor="let child of collection.children; index as i">
                    <a [routerLink]="['/c', child.slug | canonicalCollectionSlug]">
                        {{ child.name }}
                    </a>
                    <ng-container *ngFor="let grandchild of child.children; index as i">
                        <a [routerLink]="['/c', grandchild.slug | canonicalCollectionSlug]">
                            {{ grandchild.name }}
                        </a>
                    </ng-container>
                </ng-container>
            </div>
        </li>
    </ul>
</div>
<ng-template #menuTemplate>
    <div
        id="collectionDropdown"
        class="flex"
        cdkTrapFocus
        (click)="dropDownBgClick($event)"
        *ngIf="activeCollection?.children?.length && !disabled"
        (touchstart)="captureTouchStart($event)"
        (mouseenter)="setActive(activeCollection, $event)"
        (mouseleave)="close()"
        (keydown.escape)="close()"
    >
        <div
            class="p-4 p-8 mx-auto shadow-lg rounded ring-1 ring-black ring-opacity-5 text-sm inline-flex bg-white border-primary-50 border rounded"
        >
            <div
                class="h-[500px] relative w-full md:col-span-3 lg:col-span-4 grid grid-rows-[repeat(23,_minmax(0,_1fr))] grid-flow-col"
            >
                <ng-container *ngFor="let item of menuItems; index as i">
                    <a
                        *ngIf="item.collection"
                        class="w-64"
                        [ngClass]="{
                            'font-medium uppercase text-primary-900 hover:text-primary-800': item.level === 0,
                            'block text-primary-900 hover:text-primary-600': item.level === 1
                        }"
                        [routerLink]="['/c', item.collection.slug | canonicalCollectionSlug]"
                        (click)="linkClick()"
                    >
                        {{ item.collection.name }}
                    </a>
                    <div *ngIf="!item.collection"></div>
                </ng-container>
            </div>
            <div class="" *ngIf="activeCollectionFeatures.length > 0">
                <div class="font-medium uppercase text-primary-700">Featured</div>
                <div *ngFor="let feature of activeCollectionFeatures" class="mt-2">
                    <a [routerLink]="[feature.url]" (click)="linkClick()" class="text-center">
                        <kb-picture
                            [src]="feature.asset | assetPreview: 'thumb'"
                            [alt]="feature.title"
                            [focalPoint]="feature.asset.focalPoint"
                            imgClass="rounded border aspect-square w-32"
                        ></kb-picture>
                        <div class="text-primary-800 mt-1">{{ feature.title }}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
