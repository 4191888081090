import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { map, switchMap, take } from "rxjs/operators";

import { GetProductIdBySlugDocument, GetRedirectDocument } from "../common/gql/graphql";
import { DataService } from "../core/providers/data/data.service";

import { ServerResponseService } from "../core/providers/server-response/server-response.service";
import { of } from "rxjs";
import { getProductSlugFromLegacyPath } from "./redirect-shared";

@Injectable({ providedIn: "root" })
export class LegacyProductGuard {
    constructor(
        private dataService: DataService,
        private router: Router,
        private serverResponseService: ServerResponseService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        const slug = getProductSlugFromLegacyPath(route.url[0]?.path);
        return this.dataService
            .query(
                GetProductIdBySlugDocument,
                {
                    slug
                },
                { ssr: true }
            )
            .pipe(
                map((data) => data.product),
                take(1),
                switchMap((result) => {
                    if (result) {
                        return of(result);
                    } else {
                        const path = "/" + (route.url[0]?.path ?? "");
                        return this.dataService.query(GetRedirectDocument, { path }, { ssr: true }).pipe(
                            map((data) => data.redirectForPath),
                            take(1));
                    }
                }),
                map((result) => {
                    if (!result) {
                        return this.router.parseUrl("/p/404/" + slug);
                    } else {
                        const redirectPath = typeof result === 'string' ? result : `/p/${slug}`;
                        this.serverResponseService.redirect(redirectPath);
                        return this.router.parseUrl(redirectPath);
                    }
                })
            );
    }
}
