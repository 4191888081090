import { Component, OnInit } from "@angular/core";
import { AsyncPipe, NgIf } from "@angular/common";
import { Dialog } from "../../../../core/providers/modal/modal-types";
import { DialogTitleDirective } from "../../../../shared/components/modal-dialog/dialog-title.directive";
import {
    FreeShippingNoticeComponent
} from "../../../../shared/components/free-shipping-notice/free-shipping-notice.component";
import { ActiveService } from "../../../../core/providers/active-order/active.service";
import { DataService } from "../../../../core/providers/data/data.service";
import { Observable, of, switchMap } from "rxjs";
import { FreeShippingStatus } from "../../../../common/generated-types";
import { filter, map, take } from "rxjs/operators";
import { GetFreeShippingStatusDocument } from "../../../../common/gql/graphql";
import { FormatPricePipe } from "../../../../shared/pipes/format-price.pipe";
import { RouterLink } from "@angular/router";

@Component({
  selector: 'kb-free-shipping-popup',
  standalone: true,
    imports: [
        DialogTitleDirective,
        FreeShippingNoticeComponent,
        AsyncPipe,
        FormatPricePipe,
        NgIf,
        RouterLink
    ],
  templateUrl: './free-shipping-popup.component.html',
  styleUrl: './free-shipping-popup.component.css'
})
export class FreeShippingPopupComponent implements Dialog<boolean>, OnInit {
    resolveWith: (result: boolean | undefined) => void;
    freeShippingStatus$: Observable<(FreeShippingStatus & { routerLink: any[]; }) | null> | null;

    constructor(private activeService: ActiveService, private dataService: DataService) {
    }

    ngOnInit() {
        this.freeShippingStatus$ = this.activeService.activeOrder$.pipe(
            switchMap((activeOrder) =>
                activeOrder ? this.dataService
                    .query(GetFreeShippingStatusDocument)
                    .pipe(map(({ checkFreeShippingStatus }) => ({
                        ...checkFreeShippingStatus,
                        routerLink: this.getRouterLink(checkFreeShippingStatus.region),
                    }))) : of(null),
            ),
        );
    }


    private getRouterLink(region: string | null | undefined) {
        if (region?.toLowerCase().includes('highlands')) {
            return ['/a', 'delivery-to-uk-highlands-islands'];
        }
        return ['/a', 'delivery-information'];
    }
}
