import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, NgIf, NgFor } from '@angular/common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faSterlingSign, faDollarSign, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { StateService } from '../../providers/state.service';
import { StorageService } from '../../providers/storage/storage.service';
import { DropdownContentDirective } from '../../../shared/components/dropdown/dropdown-content.directive';
import { DropdownTriggerDirective } from '../../../shared/components/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '../../../shared/components/dropdown/dropdown.component';

type CurrencyItem = { code: string; icon: IconProp };

@Component({
    selector: 'kb-display-currency-select',
    templateUrl: './display-currency-select.component.html',
    styleUrls: ['./display-currency-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        DropdownComponent,
        DropdownTriggerDirective,
        FontAwesomeModule,
        DropdownContentDirective,
        NgFor,
    ],
})
export class DisplayCurrencySelectComponent implements OnInit, OnDestroy {
    activeCurrency: CurrencyItem = {
        code: 'GBP',
        icon: faSterlingSign,
    };
    availableCurrencies: CurrencyItem[] = [
        {
            code: 'GBP',
            icon: faSterlingSign,
        },
        {
            code: 'USD',
            icon: faDollarSign,
        },
        {
            code: 'EUR',
            icon: faEuroSign,
        },
    ];
    private subscription: Subscription;
    chevronDown = faChevronDown;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private stateService: StateService,
        private storageService: StorageService,
    ) {}

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            const storedDisplayCurrency = this.storageService.getFromLocalStorage('displayCurrency');
            if (storedDisplayCurrency) {
                this.setCurrency(storedDisplayCurrency);
            }

            this.subscription = this.stateService
                .select((state) => state.displayCurrency)
                .pipe(take(1))
                .subscribe((displayCurrency) => {
                    this.setCurrency(displayCurrency);
                });
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    setCurrency(value: string) {
        this.stateService.setState('displayCurrency', value);
        this.storageService.setInLocalStorage('displayCurrency', value);
        const icon = this.availableCurrencies.find((i) => i.code === value)?.icon;
        this.activeCurrency = {
            code: value,
            icon,
        };
    }
}
