import { Directive, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { isObservable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HasMetadata } from '../../../common/types';
import { MetadataService } from '../../providers/metadata/metadata.service';

/**
 * Updates title and meta tags for any components which implement the `HasMetadata` interface.
 */
@Directive({
    selector: 'router-outlet',
    standalone: true,
})
export class PageMetadataDirective implements OnDestroy {
    private readonly subscription: Subscription;

    constructor(routerOutlet: RouterOutlet, metadataService: MetadataService) {
        this.subscription = routerOutlet.activateEvents
            .pipe(
                switchMap((component: Partial<HasMetadata>) => {
                    if (isObservable(component.pageMetadata)) {
                        return component.pageMetadata;
                    } else {
                        return of(undefined);
                    }
                }),
            )
            .subscribe((metadata) => {
                metadataService.setPageMetadata(metadata);
            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
