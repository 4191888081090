import { ErrorHandler, Injectable } from '@angular/core';
import { createErrorHandler } from '@sentry/angular-ivy';

// Since we can't provide multiple error handlers, we need to compose the sentry error handler with our own
const sentryErrorHandler = createErrorHandler({
    showDialog: false,
});

/**
 * @description
 * When deploying a new build, anyone with an old build open in the browser might try to lazy-load a chunk
 * which no longer exists. This will result in a 404 error, which will be caught by this error handler and
 * just reload the page.
 *
 * Credit: https://stackoverflow.com/a/59073053/772859
 */
@Injectable()
export class ChunkLoadErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        } else {
            sentryErrorHandler.handleError(error);
        }
    }
}
