import { LayoutModule } from '@angular/cdk/layout';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { MetadataService } from './providers/metadata/metadata.service';
import { APOLLO_CLIENT_PROVIDER } from './apollo-client-provider';

@NgModule({
    imports: [LayoutModule, ApolloModule],
    providers: [APOLLO_CLIENT_PROVIDER, provideHttpClient(withFetch())],
})
export class CoreModule {
    constructor(metadataService: MetadataService) {
        metadataService.setSiteMetadata();
    }
}
